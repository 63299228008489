* {
 margin: 0;
 padding: 0;
 box-sizing: border-box;
}

:root {
 /* ===== Colors ===== */
 // --body-color: #E4E9F7;
 --body-color: #f5f5f9;
 --sidebar-color: #2c3e50;
 --sidbar-text-color: #fff;
 --primary-color: #695cfe;
 --primary-color-light: #f6f5ff;
 --toggle-color: #ddd;
 --text-color: #707070;

 /* ====== Transition ====== */
 --tran-03: all 0.2s ease;
 --tran-03: all 0.3s ease;
 --tran-04: all 0.3s ease;
 --tran-05: all 0.3s ease;
}

body {
 font-size: 16px;
 min-height: 100vh;
 background-color: var(--body-color);
 transition: var(--tran-05);
}

::selection {
 background-color: var(--primary-color);
 color: #fff;
}

.content-row {
 width: 90%;
 margin: 0px auto 0;
}

.header-login--wrapper {
 display: grid;
 grid-template-columns: repeat(2, 1fr);
 padding: 10px;
 margin-bottom: 20px;
}

.header-login--wrapper p {
 color: #fafafa;

}

.btn-info-login {
 color: #fafafa;
 border: 1px solid #fafafa;
 
 padding: 10px;
 min-width: auto;
 max-width: auto;
}

.content-header--wrapper {
 display: grid;
 background-color: #fff;
 padding: 10px;
 border-radius: 5px;
 grid-template-columns: repeat(2, 1fr);
 -webkit-border-radius: 5px;
 -moz-border-radius: 5px;
 -ms-border-radius: 5px;
 -o-border-radius: 5px;
}

.content-filter--wrapper {
 margin: 10px 0;
 background-color: #fff;
 padding: 20px 0 0 10px;
 display: grid;
 grid-template-columns: 25% 25% 25% 25%;
}

.content-body--wrapper {
 margin-top: 10px;
 background-color: #fff;
 padding: 10px;
 border-radius: 5px;
 -webkit-border-radius: 5px;
 -moz-border-radius: 5px;
 -ms-border-radius: 5px;
 -o-border-radius: 5px;
}

.content-body--wrapper td {
 text-align: center;
}

.content-header--wrapper div:nth-child(2) {
 justify-self: right;
}

.form-row {
 width: 60%;
 margin: 15px auto;
}

.th-sm {
 text-align: center;
}

.report--tables td {
 padding: 20px 0;
}
