/* ===== Sidebar ===== */
.sidebar {
 position: fixed;
 top: 0;
 left: 0;
 height: 100%;
 width: 17%;
 padding: 10px 14px;
 background: var(--sidebar-color);
 transition: var(--tran-05);
 z-index: 100;
}
.sidebar.close {
 width: 4.5%;
}

.menu-links {
 padding-left: 0;
}

/* ===== Reusable code - Here ===== */
.sidebar li {
 height: 50px;
 list-style: none;
 display: flex;
 align-items: center;
 margin-top: 10px;
}

.sidebar header .image,
.sidebar .icon {
 min-width: 60px;
 border-radius: 6px;
}

.sidebar .icon {
 min-width: 60px;
 border-radius: 6px;
 height: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
 font-size: 20px;
}

.sidebar .text,
.sidebar .icon {
 color: var(--sidbar-text-color);
 transition: var(--tran-03);
}

.sidebar .text {
 font-size: 17px;
 font-weight: 500;
 white-space: nowrap;
 opacity: 1;
}
.sidebar.close .text {
 opacity: 0;
}
/* =========================== */

.sidebar header {
 position: relative;
}

.sidebar header .image-text {
 display: flex;
 align-items: center;
}
.sidebar header .logo-text {
 display: flex;
 flex-direction: column;
}
header .image-text .name {
 margin-top: 2px;
 font-size: 18px;
 font-weight: 600;
}

header .image-text .profession {
 font-size: 16px;
 margin-top: -2px;
 display: block;
}

.sidebar header .image {
 display: flex;
 align-items: center;
 justify-content: center;
}

.sidebar header .image img {
 width: 40px;
 border-radius: 6px;
}

.sidebar header .toggle {
 position: absolute;
 top: 50%;
 right: -25px;
 transform: translateY(-50%) rotate(180deg);
 height: 25px;
 width: 25px;
 background-color: var(--primary-color);
 color: var(--sidebar-color);
 border-radius: 50%;
 display: flex;
 align-items: center;
 justify-content: center;
 font-size: 22px;
 cursor: pointer;
 transition: var(--tran-05);
}

body.dark .sidebar header .toggle {
 color: var(--text-color);
}

.sidebar.close .toggle {
 transform: translateY(-50%) rotate(0deg);
}

.sidebar .menu {
 margin-top: 40px;
}

.sidebar li a {
 list-style: none;
 height: 100%;
 background-color: transparent;
 display: flex;
 align-items: center;
 height: 100%;
 width: 100%;
 border-radius: 6px;
 text-decoration: none;
 transition: var(--tran-03);
}

.sidebar li a:hover {
 background-color: var(--primary-color);
}
.sidebar li a:hover .icon,
.sidebar li a:hover .text {
 color: var(--sidebar-color);
}
body.dark .sidebar li a:hover .icon,
body.dark .sidebar li a:hover .text {
 color: var(--text-color);
}

.sidebar .menu-bar {
 height: calc(100% - 55px);
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 overflow-y: scroll;
}
.menu-bar::-webkit-scrollbar {
 display: none;
}
.sidebar .menu-bar .mode {
 border-radius: 6px;
 background-color: var(--primary-color-light);
 position: relative;
 transition: var(--tran-05);
}

.menu-bar .mode .sun-moon {
 height: 50px;
 width: 60px;
}

.mode .sun-moon i {
 position: absolute;
}
.mode .sun-moon i.sun {
 opacity: 0;
}

.content--wrapper {
 position: absolute;
 top: 0;
 top: 0;
 left: 250px;
 height: 100vh;
 width: calc(100% - 250px);
 background-color: var(--body-color);
 transition: var(--tran-05);
 -webkit-transition: var(--tran-05);
 -moz-transition: var(--tran-05);
 -ms-transition: var(--tran-05);
 -o-transition: var(--tran-05);
}

.sidebar.close ~ .content--wrapper {
 left: 78px;
 height: 100vh;
 width: calc(100% - 78px);
}
