.chatbox-container {
 cursor: pointer;
 display: grid;
 grid-template-columns: 21% 81%;
 padding: 10px;
}

.avatar {
 vertical-align: middle;
 width: 40px;
 height: 40px;
 border-radius: 50%;
 -webkit-border-radius: 50%;
 -moz-border-radius: 50%;
 -ms-border-radius: 50%;
 -o-border-radius: 50%;
}

.chatbox-username {
 font-weight: 600;
 text-transform: capitalize;
}

.chatbox-text {
 padding-left: 1px;
}

.chatbox-new-message-icon {
 width: 10px;
 height: 10px;
 background-color: red;
 margin-top: 10px;
 border-radius: 50%;
 -webkit-border-radius: 50%;
 -moz-border-radius: 50%;
 -ms-border-radius: 50%;
 -o-border-radius: 50%;
}
