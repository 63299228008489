.conversation-username--container {
 margin-top: 10px;
 border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.conversation-username--container h4 {
 padding: 5px 7px 7px;
}

.conversation-username {
 font-size: 14px;
 text-transform: capitalize;
 color: rgb(85, 110, 230);
}

.conversation--wrapper {
 padding: 0;
 min-height: 80vh;
 max-height: 80vh;
 overflow: scroll;
}

.conversation-text--wrapper {
 display: flex;
}

.conversation-text {
 display: inline-block;
 background: #eef0fc;
 margin-top: 10px;
 margin-left: 10px;
 padding: 10px 20px;
 border-radius: 5%;
 -webkit-border-radius: 5%;
 -moz-border-radius: 5%;
 -ms-border-radius: 5%;
 -o-border-radius: 5%;
}

.conversation-timestamp {
 font-size: 12px;
 font-weight: 500;
}

.conversation-text--container {
 min-height: 70vh;
 max-height: 70vh;
 overflow: scroll;
 -ms-overflow-style: none; /* Internet Explorer 10+ */
 scrollbar-width: none; /* Firefox */
 display: flex;
 flex-direction: column-reverse;
}

.conversation-text--container::-webkit-scrollbar {
 display: none; /* Safari and Chrome */
}

.conversation-sendmessage--container {
 display: grid;
 grid-template-columns: 65% 35%;
}

#send-message-btn {
 width: 85%;
 margin-top: 14px;
 background-color: rgb(85, 110, 230);
}

#send-message-input {
 background: #eef0fc;
 border-radius: 25px;
 border: 2px solid #eef0fc;
 padding: 20px;
 width: 96%;
 height: 15px;
 font-size: 14px;
 outline: none;
}

#send-message-input:focus {
 border: 2px solid rgb(85, 110, 230);
}

.media-links--wrapper {
 display: flex;
 flex-direction: row;
 overflow-x: auto;
 width: 100%;
 gap: 0 10px;
 margin-top: 20px;
 padding: 1px;
}

.media-content {
 min-width: 120px;
 padding: 5px 10px;
 cursor: pointer;
 box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
  rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
 border-radius: 9%;
 -webkit-border-radius: 9%;
 -moz-border-radius: 9%;
 -ms-border-radius: 9%;
 -o-border-radius: 9%;
}

.media-links-offer--wrapper::-webkit-scrollbar {
 width: 0px;
 background: transparent;
}
